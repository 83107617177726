@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Noto+Sans+KR:wght@100;300;400;500;700;900&family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@font-face {
    font-family: 'NanumSquareRoundOTF';
    font-weight: 300;
    src: url('./common/fonts/NanumSquareRoundOTFL.woff') format('woff');
}
@font-face {
    font-family: 'NanumSquareRoundOTF';
    font-weight: 500;
    src: url('./common/fonts/NanumSquareRoundOTFR.woff') format('woff');
}
@font-face {
    font-family: 'NanumSquareRoundOTF';
    font-weight: 600;
    src: url('./common/fonts/NanumSquareRoundOTFB.woff') format('woff');
}
@font-face {
    font-family: 'NanumSquareRoundOTF';
    font-weight: 800;
    src: url('./common/fonts/NanumSquareRoundOTFEB.woff') format('woff');
}
html {

}
body {
    margin: 0;
    font-family: -apple-system, 'NanumSquareRoundOTF', 'Montserrat', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /*overflow-y: scroll !important;*/
    padding-right: 0 !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/*.MuiDialog-root{*/
/*    overflow-y: scroll;*/
/*}*/
.tooltip {
    display: inline-block;
    position: relative;
    /*color: deeppink;*/
    /*font-weight: bold;*/
}

.tooltip .tooltip-text {
    /*display: none;*/
    visibility: hidden;
    width: 120px;
    background-color: rgb(220, 214, 214);
    color: #000000;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    z-index: 500;
    position: absolute;
}
.tooltip:hover .tooltip-text {
    /*display: block;*/
    visibility: visible;
    z-index: 500;
}

/*.tooltip .tooltip-text::after {*/
/*    content: " ";*/
/*    position: absolute;*/
/*    border-style: solid;*/
/*    border-width: 5px;*/
/*}*/

/*.tooltip .tooltip-top {*/
/*    width: 120px;*/
/*    bottom: 150%;*/
/*    left: 50%;*/
/*    margin-left: -60px;*/
/*    z-index: 500;*/
/*}*/

/*.tooltip .tooltip-top::after {*/
/*    top: 100%;*/
/*    left: 50%;*/
/*    margin-left: -5px;*/
/*    border-color: black transparent transparent transparent;*/
/*    z-index: 500;*/
/*}*/

